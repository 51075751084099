import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-pagefooter',
  templateUrl: './pagefooter.component.html',
  styleUrls: ['./pagefooter.component.css']
})
export class PagefooterComponent implements OnInit {
  headers: any;
  prdlist:any = [];
  APIBASEURL = 'https://www.gransainteriors.com/console/api/';
  IMAGEURL   = 'https://www.gransainteriors.com/console/';
  // APIBASEURL = 'http://localhost:8021/gransa/console/api/';
 //  IMAGEURL   = 'http://localhost:8021/gransa/console/';
  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, public  router: Router) { }

  ngOnInit() {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    $(".quick_contact_btn").click(function(){
      $(".quick_contact_popup_section").show();
  });
  $(".right_form_sec_pop_cls").click(function(){
      $(".quick_contact_popup_section").hide();
  });
   this.http.get(this.APIBASEURL + 'product_list').subscribe((data: any) => {
      this.prdlist = data;
      },
      err =>{
        console.log('Error- something is wrong!');
      });

  }
  remove_wrning(textid){
    $('#' + textid).removeClass('input_error_alert');
  }
  get_product(id, pname)
  {
    $('#page_loader').show();
    let proname = pname.replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['product'], { queryParams: { type: id,name :proname} });
  }
   submit_enq_form() {
	   $('input:text').removeClass('input_error_alert');
    // $('.quick_contact_popup_section').hide();
    // $('.form_loader').hide();
    // $('#success_popup_sec').show();
    let name:any = $('#frm_name').val();
    let email: any = $('#frm_email').val();
    let phone:any = $('#frm_phone').val();
    let place:any = $('#frm_place').val();
    let message:any = $('#frm_message').val();
    const emailPattern: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexp: any = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
 
   if(name.trim()=='' || name.match(regexp)) {
      $('#frm_name').addClass('input_error_alert');
      $('#frm_name').focus();
    } else  if(email=='') {
      $('#frm_email').addClass('input_error_alert');
	  $('#frm_email').focus();
    } else if (email != '' && !email.match(emailPattern)) {
      $('#frm_email').addClass('input_error_alert');
      $('#frm_email').focus();
    }  else  if(phone.trim()=='' || phone.length<10 || isNaN(phone)==true  || phone.match(regexp)) {
      $('#frm_phone').addClass('input_error_alert');
      $('#frm_phone').focus();
    } else  if(place.trim()==''  || place.match(regexp)) {
      $('#frm_place').addClass('input_error_alert');
      $('#frm_place').focus();
    }  else  if(message.trim()!='' && message.match(regexp)) {
       $('#place').addClass('input_error_alert');
     } else {
      $('.form_loader').show();
     let body = {'name': name , 'email' : email, 'phone': phone , 'place' : place, 'message': message, 'page_name': 'Quick Contact'};
      return this.http.post(this.APIBASEURL +'save_contact_info_info' , body, { headers: this.headers, responseType: 'text' }  )
        .subscribe(
          (res: any) => {
            $('#frm_name').val('');
            $('#frm_email').val('');
            $('#frm_phone').val('');
            $('#frm_place').val('');
            $('#frm_message').val('');
            $('.form_loader').hide();
            $('.quick_contact_popup_section').hide();
            $('#success_popup_sec').show();
          }, error => {
            console.log('There was an error: ', error);
          });
    }
  }
  close_succ_popup() {
    $('#success_popup_sec').hide();
  }
}
