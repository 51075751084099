import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { PageheaderComponent } from '../pageheader/pageheader.component';
import * as $ from 'jquery';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  @ViewChild(PageheaderComponent) child;
  project_list_array: any = [];
  project_gallery_length: any;
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get(this.child.APIBASEURL  + 'public_project_list', ).subscribe((data: any) => {
        this.project_list_array = data.project_list;
        this.project_gallery_length = data.gallery_length;
        $('#page_loader').hide();
      },
      err => {
        console.log('Error- something is wrong!');
      });
  }

}
