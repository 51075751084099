import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import {DatePipe} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NumberOnlyDirective } from './number.directive';
import { FormsModule } from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NouisliderModule } from 'ng2-nouislider';
import * as $ from 'jquery';
import { NgFlashMessagesModule } from 'ng-flash-messages';
import { AgmCoreModule } from '@agm/core';


import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
  LinkedinLoginProvider,
  VkontakteLoginProvider,
  
} from 'angular-6-social-login-v2';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { Ng5SliderModule } from 'ng5-slider';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlModule } from 'ngx-owl-carousel';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PageheaderComponent } from './pageheader/pageheader.component';
import { PagefooterComponent } from './pagefooter/pagefooter.component';
import { ProductComponent } from './product/product.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { GalleryComponent } from './gallery/gallery.component';
import { OffersComponent } from './offers/offers.component';
import { ProjectsComponent } from './projects/projects.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { BedroomComponent } from './bedroom/bedroom.component';
import { BedRoomComponent } from './bed-room/bed-room.component';
import { KitchenComponent } from './kitchen/kitchen.component';
import { KidsRoomComponent } from './kids-room/kids-room.component';
import { DiningRoomComponent } from './dining-room/dining-room.component';
import { LivingRoomComponent } from './living-room/living-room.component';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { Page404Component } from './page404/page404.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CareersComponent } from './careers/careers.component';

// Configs
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('356613551746573')
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('487414905093-p2j0kg93tdbck9vtce7olfqo5741t7ed.apps.googleusercontent.com')
      },
      // {
      //   id: VkontakteLoginProvider.PROVIDER_ID,
      //   provider: new VkontakteLoginProvider("Your-VK-Client-Id")
      // },
      // {
      //   id: LinkedinLoginProvider.PROVIDER_ID,
      //   provider: new LinkedinLoginProvider("1098828800522-m2ig6bieilc3tpqvmlcpdvrpvn86q4ks.apps.googleusercontent.com")
      // },
    ],
  );
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NumberOnlyDirective,
    PageheaderComponent,
    PagefooterComponent,
    ProductComponent,
    ProductDetailComponent,
    ContactUsComponent,
    GalleryComponent,
    OffersComponent,
    ProjectsComponent,
    TermsConditionsComponent,
    TestimonialComponent,
    WhoWeAreComponent,
    BedroomComponent,
    BedRoomComponent,
    KitchenComponent,
    KidsRoomComponent,
    DiningRoomComponent,
    LivingRoomComponent,
    ProductDetailsComponent,
    Page404Component,
    LandingPageComponent,
    CareersComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'my-app'}),
    CommonModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    HttpClientModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    AngularFontAwesomeModule,
    FormsModule,
    ScrollToModule.forRoot(),
    NouisliderModule,
    CarouselModule,
    OwlModule,
    NgFlashMessagesModule.forRoot(),
    AgmCoreModule.forRoot(  {
      apiKey: 'AIzaSyAkn54Nm9kq4Xd1tbjeuj5JAAVkvIsrsUc',
      libraries: ['places', 'institutions']
    }),
    SocialLoginModule,
    JwSocialButtonsModule,
    Ng5SliderModule, NgbModule, BrowserAnimationsModule
  ],
 
  providers: [
    DatePipe,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }
  ],
})
export class AppModule { }
