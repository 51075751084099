import {Component, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { PageheaderComponent } from '../pageheader/pageheader.component';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  @ViewChild(PageheaderComponent) child;
  subcatid: any;
  subcat_info: any = [];
  subcat_gallery: any = [];
  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, public  router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['subtype']) {
         this.subcatid = atob(params['subtype']);
        window.scrollTo(0, 0);
        this.http.get(this.child.APIBASEURL + 'product_subcat_info/' + this.subcatid).subscribe((data: any) => {
                this.subcat_info    = data;
                this.subcat_gallery = data.subcat_gallery;
            $('#page_loader').hide();
          },
          err => {
            console.log('Error- something is wrong!');
          });
      } else {
         this.router.navigate(['/home']);
     }
    });
  }

}
