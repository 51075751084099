import { Component, OnInit, ViewChild,  ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
declare let google: any;
import { FormControl } from '@angular/forms';
import * as $ from 'jquery';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PageheaderComponent} from '../pageheader/pageheader.component';
import { Title, Meta }  from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild(PageheaderComponent) child;
  home_banner_list: any = [];
  project_list_array: any = [];
  testimonails_list: any = [];
  product_list: any = [];
  completedprojects: any = {
    slideSpeed : 300,
    //singleItem : true,
    autoPlay : 5000,
    pagination: false,
    dots:  false,
    items : 3,
    loop:true,
    responsiveClass:true,
    navigation: true,
    navText: ["<img src='assets/images/prev_arrow.png'> PREV","NEXT <img src='assets/images/next_arrow.png'>"],
  responsive:{
    0:{
      items:1,
   
    },
    600:{
      items:3,
      nav:false
    },
    800:{
      items:3,

  
    },
    1000:{
      items:3,
     

    }
		}
  }

  testimonial: any = {
    slideSpeed : 300,
    //singleItem : true,
    autoPlay : 5000,
    pagination: true,
    dots: true,
    // "singleItem:true' is a shortcut for:
     items : 3,
    responsiveClass:true,
    itemsDesktop : [1199,3],
    itemsDesktopSmall : [980,3],
    itemsTablet: [768,2],
    itemsTabletSmall: false,
    itemsMobile : [479,1],
    singleItem : false,
    responsive:{
      0:{
        items:1,
     
      },
      600:{
        items:1,
      },
      800:{
        items:2,
  
    
      },
      1000:{
        items:3,
       
  
      }
    }
  }


  branch: any = {
    slideSpeed : 300,
    //singleItem : true,
    autoPlay : 5000,
    pagination: true,
    dots: true,
    // 'singleItem:true' is a shortcut for:
     items : 4,
     navigation: true,
    navText: ["<img src='assets/images/prev_arrow.png'> PREV","NEXT <img src='assets/images/next_arrow.png'>"],
     responsive:{
      0:{
        items:1,
     
      },
      600:{
        items:2,
      },
      800:{
        items:3,
  
    
      },
      1000:{
        items:4,
       
  
      }
    }
   
  }
  wowsliderbanner: any = {
    items : 1,
    navigation : false,
    singleItem : true,
    autoplay:true,
    stopOnHover : false,
    autoplayTimeout:4000,
    autoPlay : 5000,
    paginationSpeed : 2000,
    autoplaySpeed: 550,
    navSpeed: 500,
    transitionStyle: 'goDown',
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    loop:true,
    dots:false,
    responsive:{
      0:{
        items:1,
        dots:false,
      },
      600:{
        items:1,
      },
      800:{
        items:1,
    
      },
      1000:{
        items:1,
       
  
      }
    }
  };


  constructor(private titleService: Title, private meta: Meta, private http: HttpClient, public  router: Router, private activatedRoute: ActivatedRoute, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {
  }
  public ngOnInit() {

    $('.counter-count').each(function () {
      $(this).prop('Counter',0).animate({
          Counter: $(this).text()
      }, {
          duration: 5000,
          easing: 'swing',
          step: function (now) {
              $(this).text(Math.ceil(now));
          }
      });
  });

    var top_header = $('.left_part_1');
    top_header.css({'top':'0%'}); // better use CSS

    $(window).scroll(function () {
    var st = $(this).scrollTop();
    top_header.css({'top':+(st*.029)+'%'});
    });

    var right_sec = $('.right_part_1');
    right_sec.css({'top':'0%'}); // better use CSS

    $(window).scroll(function () {
    var zt = $(this).scrollTop();
    right_sec.css({'top':+(zt*.019)+'%'});
    });

    window.scrollTo(0, 0);
    this.http.get(this.child.APIBASEURL  + 'public_home_banner', ).subscribe((data: any) => {
        this.home_banner_list = data.home_banner;
        this.http.get(this.child.APIBASEURL  + 'home_content_list', ).subscribe((data: any) => {
            this.project_list_array = data.project_list;
			this.product_list = data.pdt_list;
			this.testimonails_list = data.testimonials;
          },
          err => {
            console.log('Error- something is wrong!');
          });
      
        $('#page_loader').hide();
       // $('#gransa_offer_popup_section').show();

      },
      err => {
        console.log('Error- something is wrong!');
      });

  }
  view_project_name(indx) {
    $('.prjctnameh').hide();
    $('#work_' + indx).show();
  }
}
