import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { PagefooterComponent } from './pagefooter/pagefooter.component';
import { PageheaderComponent } from './pageheader/pageheader.component';

import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { GalleryComponent } from './gallery/gallery.component';
import { OffersComponent } from './offers/offers.component';
import { ProjectsComponent } from './projects/projects.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { BedroomComponent } from './bedroom/bedroom.component';
import { KitchenComponent } from './kitchen/kitchen.component';
import { KidsRoomComponent } from './kids-room/kids-room.component';
import { DiningRoomComponent } from './dining-room/dining-room.component';
import { LivingRoomComponent } from './living-room/living-room.component';
import { Page404Component } from './page404/page404.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CareersComponent } from './careers/careers.component';

const routes: Routes = [
  {path : '' , component: HomeComponent},
  {path : 'home' , component: HomeComponent, data: { preload: true }},
  {path : 'pagefooter' , component: PagefooterComponent, data: { preload: true }},
  {path : 'pageheader' , component: PageheaderComponent, data: { preload: true }},
  //{path : 'product' , component: ProductComponent, data: { preload: true }},
  {path : 'products/:id' , component: ProductComponent},
  {path : 'product-detail' , component: ProductDetailComponent, data: { preload: true }},
  {path : 'contact_gransa_home_interiors_kochi' , component: ContactUsComponent, data: { preload: true }},
  {path : 'best_home_interior_decorators_south_india' , component: GalleryComponent, data: { preload: true }},
  {path : 'gransa_offers_packages' , component: OffersComponent, data: { preload: true }},
  {path : 'best_home_interior_projects' , component: ProjectsComponent, data: { preload: true }},
  {path : 'terms-conditions' , component: TermsConditionsComponent, data: { preload: true }},
  {path : 'our_clients_projects' , component: TestimonialComponent, data: { preload: true }},
  {path : 'gransa_home_interior_designers_kochi_kerala' , component: WhoWeAreComponent, data: { preload: true }},
  {path : 'bedroom' , component: BedroomComponent, data: { preload: true }},
  {path : 'kitchen' , component: KitchenComponent, data: { preload: true }},
  {path : 'kids-room' , component: KidsRoomComponent, data: { preload: true }},
  {path : 'dining-room' , component: DiningRoomComponent, data: { preload: true }},
  {path : 'living-room' , component: LivingRoomComponent, data: { preload: true }},
  {path : 'careers' , component: CareersComponent, data: { preload: true }},
  {path : 'gransa-home-Interiors-cochin-kerala' , component: LandingPageComponent, data: { preload: true }},
   {path : '404' , component: Page404Component, data: { preload: true }},
  {path : '**' , component: Page404Component, data: { preload: true }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
  AppComponent,
  HomeComponent,
  PageheaderComponent,
  PagefooterComponent,
  ProductComponent,
  ProductDetailComponent,
  ContactUsComponent,
  GalleryComponent,
  OffersComponent,
  ProjectsComponent,
  TermsConditionsComponent,
  TestimonialComponent,
  WhoWeAreComponent,
];
