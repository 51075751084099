import {Component, OnInit, ViewChild} from '@angular/core';
import { PageheaderComponent } from '../pageheader/pageheader.component';
import * as $ from 'jquery';
@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  @ViewChild(PageheaderComponent) child;
  constructor() { }

  ngOnInit() {
    $('.get_estimate_popup_cls').click(function(){
      $('#page_name').val('');
      $('.get_estimate_popup_cc').hide();
    });
    $('#page_loader').hide();
  }
  get_an_estimate(offername) {
    $('.get_estimate_popup_cc').show();
    $('#page_name').val(offername);
  }
  got_offer_tab(tabid) {
      $('.setup-content').hide();
      $('.tab_btn').addClass('tab_disable');
      $('#' + tabid + '-h').removeClass('tab_disable');
      $('#' + tabid).show();
  }
}
