import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { PageheaderComponent } from '../pageheader/pageheader.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  @ViewChild(PageheaderComponent) child;
  gallert_list_array: any = [];
  constructor(private http: HttpClient) { }

  ngOnInit() {
    $('#quick_contact_btn').hide();
    $('#page_loader').hide();
  }

}
