import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dining-room',
  templateUrl: './dining-room.component.html',
  styleUrls: ['./dining-room.component.css']
})
export class DiningRoomComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
