import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import * as $ from 'jquery';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.css']
})
export class PageheaderComponent implements OnInit {
url:any;
  headers:any;
  body:any;
prdlist:any = [];
  constructor(private titleService: Title, private meta: Meta, private http: HttpClient, private activatedRoute: ActivatedRoute, public  router: Router) {

 }
  APIBASEURL = 'https://www.gransainteriors.com/console/api/';
  IMAGEURL   = 'https://www.gransainteriors.com/console/';
  // APIBASEURL = 'http://localhost:8021/gransa/console/api/';
   //IMAGEURL   = 'http://localhost:8021/gransa/console/';
  ngOnInit() {
	  $(".mobile-menu").removeClass("open_mob_menu");
      $("#sidenav-overlay").css('display','none');
      $(".open_menu").removeClass("close_menu");
      $(".open_menu").addClass("open_menu");
    var routeurl  = this.router.url;
    let routename:any = routeurl.substr(1);
	if(routeurl.startsWith('product')==false){
    this.http.get(this.APIBASEURL  + 'page_seo_contants?pname=' + routename, ).subscribe((data: any) => {
        let response_data:any = data;
		if(response_data.length!=0){
        this.titleService.setTitle(response_data[0].meta_title);
        this.meta.updateTag({ name: 'keywords', content: response_data[0].meta_keywords });
        this.meta.updateTag({ name: 'description', content: response_data[0].meta_desc  });
		}
      },
      err => {
        console.log('Error- something is wrong!');
      });
	}
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   window.scrollTo(0, 0);
      this.http.get(this.APIBASEURL + 'product_list').subscribe((data: any) => {
      this.prdlist = data;
      },
      err =>{
        console.log('Error- something is wrong!');
      });

  

jQuery(window).scroll(function() {
var scroll = jQuery(window).scrollTop();

if (scroll >= 200) {
  jQuery(".main_top_area").addClass("scroll-to-fixed-fixed");
} else {
  jQuery(".main_top_area").removeClass("scroll-to-fixed-fixed");
}

});


}
mobile_tab_mgt() {
	if($("#sidenav-overlay").css('display')=='block'){
			  $(".mobile-menu").removeClass("open_mob_menu");
			  $("#sidenav-overlay").css('display','none');
			  $(".open_menu").removeClass("close_menu");
			  $(".open_menu").addClass("open_menu");
		} else {
			  $(".mobile-menu").addClass("open_mob_menu");
			  $("#sidenav-overlay").css('display','block');
			  $(".open_menu").addClass("close_menu");
			  $(".open_menu").removeClass("open_menu");
		}
}
get_product(id, pname)
  {
    $('#page_loader').show();
    let proname = pname.replace(/\s+/g, '-').toLowerCase();
    this.router.navigate(['product'], { queryParams: { type: id,name :proname} });
  }
  remove_wrning(textid){
    $('#' + textid).removeClass('input_error_alert');
  }
  close_succ_popup(popid) {
    $('#' + popid).hide();
  }
   submit_enq_form(source: any) {
	   $('input:text').removeClass('input_error_alert');
     let name:any = $('#name').val();
     let email: any = $('#email').val();
     let phone:any = $('#phone').val();
     let place:any = $('#place').val();
     let message:any = $('#message').val();
     let page_name:any = $('#page_name').val();
    const emailPattern: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexp: any = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

	if(name.trim()=='' || name.match(regexp)) {
       $('#name').addClass('input_error_alert');
       $('#name').focus();
     } else  if(email=='') {
       $('#email').addClass('input_error_alert');
       $('#email').focus();
     } else if (email != '' && !email.match(emailPattern)) {
      $('#email').addClass('input_error_alert');
      $('#email').focus();
    }  else  if(phone.trim()=='' || phone.match(regexp)) {
       $('#phone').addClass('input_error_alert');
       $('#phone').focus();
     }  else  if(phone.length<10 || isNaN(phone) == true) {
       $('#phone').addClass('input_error_alert');
       $('#phone').focus();
     }  else  if(place.trim()=='' || place.match(regexp)) {
       $('#place').addClass('input_error_alert');
       $('#phone').focus();
     }  else  if(message && message.trim()!='' && message.match(regexp)) {
       $('#place').addClass('input_error_alert');
       $('#place').focus();
     } else{
      $('.form_loader').show();
      this.body = {"source": source,'name': name , 'email' : email, 'phone': phone , 'place' : place, 'message': message, 'page_name': page_name};
      return this.http.post(this.APIBASEURL + 'save_contact_info_info' , this.body, { headers: this.headers, responseType: 'text' }  )
        .subscribe(
          (res: any) => {
            $('#name').val('');
            $('#email').val('');
            $('#phone').val('');
            $('#place').val('');
            $('#message').val('');
            $('#success_popup_sec').show();
            $('.form_loader').hide();
            $('.quick_contact_popup_section').hide();
            $('.get_estimate_popup_cc').hide();
          }, error => {
            console.log('There was an error: ', error);
          });
     }
  }

}
