import { Component, OnInit, ViewChild,  ElementRef, NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as $ from 'jquery';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PageheaderComponent} from '../pageheader/pageheader.component';
import { Title, Meta }  from '@angular/platform-browser';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
@ViewChild(PageheaderComponent) child;
url:any;
parm1:any;
category_info:any=[];
product_name:any;
pdt_title:any;
pdt_desc:any;
imgurl:any;
pdt_banner:any;
ban_alt:any;
primg:any;
inner_img:any;
inner_alt:any;
gal_cat:any;
smart_kitchen: any = {
    slideSpeed : 300,
    //singleItem : true,
    autoPlay : 5000,
    pagination: false,
    dots: false,
    // "singleItem:true" is a shortcut for:
     items : 4,
    //  navigation: true,
    //  navigationText: ["<img src='assets/images/arrow_prev.png'>","<img src='assets/images/arrow_next.png'>"],
    margin : 20,
    singleItem : false,
    responsive:{
      0:{
        items:1,
     
      },
      600:{
        items:3,
        nav:false
      },
      800:{
        items:3,
  
    
      },
      1000:{
        items:4,
       
  
      }
      }
  }

  constructor(private titleService: Title, private meta: Meta, private http: HttpClient, public  router: Router, private activatedRoute: ActivatedRoute) {
	
	}

  ngOnInit() {
	 this.activatedRoute.paramMap.subscribe( paramMap => {
		 $(".mobile-menu").removeClass("open_mob_menu");
      $("#sidenav-overlay").css('display','none');
      $(".open_menu").removeClass("close_menu");
      $(".open_menu").addClass("open_menu");
      let pageurl:any = paramMap.get('id');  $('#page_loader').hide();
	   this.http.get(this.child.APIBASEURL + 'productsection_list/' + pageurl).subscribe((data: any) => {
		     if(data.length==0){
				this.router.navigate(['**'], { skipLocationChange : true });
		   } else{
		   
			 this.category_info = data.category_list;
			 this.product_name = data.pdt_name;
			 this.pdt_desc = data.pdt_desc;
			 this.pdt_title = data.pdt_title;
			 this.pdt_banner = data.ban_img;
			 this.ban_alt = data.ban_alt;
			 this.inner_img = data.inner_img;
			 this.inner_alt = data.inner_alt;
			 this.gal_cat = data.gal_cat;
			 this.inner_alt = this.child.IMAGEURL;
				 this.titleService.setTitle(data.meta_title);
				 this.meta.updateTag({ name: 'keywords', content: data.meta_keywords });
				 this.meta.updateTag({ name: 'description', content: data.meta_des });
					$('#page_loader').hide();
					window.scrollTo(0, 0);
	   }
          },
              err => {
              console.log('Error- something is wrong!');
     });
    });  
    
  }
goto_gallery(){
    this.router.navigate(['best_home_interior_decorators_south_india'], { queryParams: { gtoken: btoa(this.gal_cat)} });
}

  
}
