import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kids-room',
  templateUrl: './kids-room.component.html',
  styleUrls: ['./kids-room.component.css']
})
export class KidsRoomComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
