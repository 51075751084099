import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bed-room',
  templateUrl: './bed-room.component.html',
  styleUrls: ['./bed-room.component.css']
})
export class BedRoomComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
