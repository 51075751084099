import { Component, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PageheaderComponent} from '../pageheader/pageheader.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  @ViewChild(PageheaderComponent) child;
  testimonails_list: any = [];
  wowsliderbanner: any = {
    navigation : false,
    slideSpeed : 5000,
    paginationSpeed : 400,
    items : 1,
    pagination: true,
    autoPlay : 5000,
    autoPlaySpeed: 2000,
    autoPlayTimeout: 2000,
    singleItem:true,
  };
  testimonial: any = {
    slideSpeed : 300,
    //singleItem : true,
    autoPlay : 5000,
    pagination: true,
    dots: true,
    // "singleItem:true' is a shortcut for:
     items : 4,
    responsiveClass:true,
    itemsDesktop : [1199,3],
    itemsDesktopSmall : [980,3],
    itemsTablet: [768,2],
    itemsTabletSmall: false,
    itemsMobile : [479,1],
    singleItem : false,
    responsive:{
      0:{
        items:1,
     
      },
      600:{
        items:1,
      },
      800:{
        items:2,
  
    
      },
      1000:{
        items:3,
       
  
      }
    }
  }

  
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get(this.child.APIBASEURL  + 'home_content_list', ).subscribe((data: any) => {
  this.testimonails_list = data.testimonials;
    },
    err => {
      console.log('Error- something is wrong!');
    });
    $('#quick_contact_btn').hide();
    $("#loading").hide();
    
  }

}
