import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-living-room',
  templateUrl: './living-room.component.html',
  styleUrls: ['./living-room.component.css']
})
export class LivingRoomComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
