import { Component, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PageheaderComponent} from '../pageheader/pageheader.component';
@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  @ViewChild(PageheaderComponent) child;
  careers_list: any = [];
  file_re:any;
  constructor(private http: HttpClient) { }
  
  ngOnInit() {
    $("#loading").hide();
    $("#page_loader").hide();
    $(".top_enquire_btn").click(function(){
      $(".enquiry_pop_sec").show();
    });
    $(".enquiry_pop_close").click(function(){
      $(".enquiry_pop_sec").hide();
    });
  
  
  
    $(".career_apply_btn").click(function(){
      $(".job_apply_pop_sec").show();
    });
  
    $(".job_apply_pop_close").click(function(){
      $(".job_apply_pop_sec").hide();
    });
  
    this.http.get(this.child.APIBASEURL  + 'fetch_vacancies', ).subscribe((data: any) => {
      this.careers_list = data.careers_list;
        },
        err => {
          console.log('Error- something is wrong!');
        });
  
  }
job_apply(optn){
  $("#job_apply_pop_sec").css('display',optn);
  $("#cand_name").val("");
  $("#cand_email").val("");
  $("#cand_phn").val("");
  $("#cand_qualftn").val("");
  $("#cand_experience").val("");
  $("#cand_msg").val("");
  $("#cand_resume").val("");
  this.file_re = ""
}
submtit_jobapplication(){
   let cand_name : any = $("#cand_name").val();
   let cand_email: any = $("#cand_email").val();
   let cand_phn: any = $("#cand_phn").val();
   let cand_qualftn: any = $("#cand_qualftn").val();
   let cand_experience: any = $("#cand_experience").val();
   let cand_msg: any = $("#cand_msg").val();
   const emailPattern: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   const regexp: any = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
   if(cand_name=='') {
    $('#cand_name').addClass('input_error_alert');
    $('#cand_name').focus();
  } else if(cand_email=='') {
    $('#cand_email').addClass('input_error_alert');
    $('#cand_email').focus();
  } else if (cand_email != '' && !cand_email.match(emailPattern)) {
    $('#cand_email').addClass('input_error_alert');
    $('#cand_email').focus();
  }  else  if(cand_phn.trim()=='' || cand_phn.match(regexp)) {
     $('#cand_phn').addClass('input_error_alert');
     $('#cand_phn').focus();
  }  else  if(cand_phn.length<10 || isNaN(cand_phn) == true) {
     $('#cand_phn').addClass('input_error_alert');
     $('#cand_phn').focus();
  } else  if(cand_qualftn.trim()=='') {
    $('#cand_qualftn').addClass('input_error_alert');
    $('#cand_qualftn').focus();
  }  else  if(cand_experience.trim()=='') {
    $('#cand_experience').addClass('input_error_alert');
    $('#cand_experience').focus();
  }   else  if(!this.file_re) {
    $('#cand_resume').addClass('input_error_alert');
    $('#cand_resume').focus();
  } else {
    $('.form_loader').show();
    const upload_data = new FormData();
      upload_data.append('cand_name', cand_name);
      upload_data.append('cand_email', cand_email);
      upload_data.append('cand_phn', cand_phn);
      upload_data.append('cand_qualftn', cand_qualftn);
      upload_data.append('cand_experience', cand_experience);
      upload_data.append('cand_msg', cand_msg);
      upload_data.append('file_resume', this.file_re);
      return this.http.post(this.child.APIBASEURL + 'submit_career_form' , upload_data  )
            .subscribe(
              (result: any)  => {
                
                if (result.msg =='ok') {
                  $('#success_popup_sec').show();
                  this.job_apply('none');
                  
                } else {
                  $('#cand_resume').addClass('input_error_alert');
                  $('#cand_resume').focus();
                }

              }, error => {
                console.log('There was an error: ', error);
              });
   }
}
resume_upload(event)
  {
    this.file_re = event.target.files[0];
  }
}
