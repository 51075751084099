import { Component, OnInit, ViewChild,  ElementRef, NgZone } from '@angular/core';
import * as $ from 'jquery';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PageheaderComponent} from '../pageheader/pageheader.component';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.css']
})
export class WhoWeAreComponent implements OnInit {
  @ViewChild(PageheaderComponent) child;
  dealers_list: any = [];
  dealers: any = {
    slideSpeed : 300,
		  autoPlay : 5000,
			pagination: true,
      dots: true,
      items : 6,
		  responsiveClass:true,
      itemsDesktop : [1199,6],
      itemsDesktopSmall : [980,5],
      itemsTablet: [768,3],
      itemsTabletSmall: false,
      itemsMobile : [479,2],
      singleItem : false,
}
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get(this.child.APIBASEURL  + 'public_main_dealers', ).subscribe((data: any) => {
        this.dealers_list = data.dealers_list;
        $('#page_loader').hide();
      },
      err => {
        console.log('Error- something is wrong!');
      });
  }
  menu_btn_sel(menuhid) {
    $('.btn-circle').removeClass('btn-info');
    $('#' + menuhid).addClass('btn-info');
  }
  goto_tab(tabcid) {
      $('.tabcomnclass').removeClass('__active');
      $('#' + tabcid + 'h').addClass('__active');
      $('#' + tabcid).addClass('__active');
  }
}
