import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { PageheaderComponent } from '../pageheader/pageheader.component';
import * as $ from 'jquery';
import {ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  @ViewChild(PageheaderComponent) child;
  gallert_list_array: any = [];
  gallert_cats: any = [];
  existing_gcat: any = 0;
  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, public  router: Router) { }

  ngOnInit() {
	   window.scrollTo(0, 0);
	   this.http.get(this.child.APIBASEURL  + 'gallery_cats', ).subscribe((data: any) => {
        this.gallert_cats = data.gcat_list;
      },
      err => {
        console.log('Error- something is wrong!');
      });
	
let flag=0;

    this.activatedRoute.queryParams.subscribe(params => {
		  if (params['gtoken']) {
			  flag=1;
			  this.existing_gcat = atob(params['gtoken']);
			this.gallery_list(this.existing_gcat);
		  } 
		});
    if(flag==0){
		this.gallery_list(0);
	}
	  
  }
gallery_list(indx){
	 $('#page_loader').hide();
	this.existing_gcat = indx;
	let headers = new HttpHeaders();
    headers  = headers.append('header-1', 'value-1');
    headers  = headers.append('header-2', 'value-2');
    let params = new HttpParams();
    params = params.append('timetoken', btoa(this.existing_gcat));
    this.http.get(this.child.APIBASEURL  + 'public_gallery_img', { params: params }).subscribe((data: any) => {
        this.gallert_list_array = data.gallery_list;
        $('#page_loader').hide();
      },
      err => {
        console.log('Error- something is wrong!');
      });
}
}
