import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { PageheaderComponent } from '../pageheader/pageheader.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {
  @ViewChild(PageheaderComponent) child;
  testimonails_list: any = [];
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get(this.child.APIBASEURL  + 'public_testimonials', ).subscribe((data: any) => {
        this.testimonails_list = data.testimonials;
        $('#page_loader').hide();
      },
      err => {
        console.log('Error- something is wrong!');
      });
  }

}
