import { Component, OnInit, ViewChild,  ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
declare let google: any;
import { FormControl } from '@angular/forms';
import * as $ from 'jquery';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PageheaderComponent} from '../pageheader/pageheader.component';
import { Title, Meta }  from '@angular/platform-browser';

@Component({
  selector: 'app-kitchen',
  templateUrl: './kitchen.component.html',
  styleUrls: ['./kitchen.component.css']
})
export class KitchenComponent implements OnInit {

  smart_kitchen: any = {
    slideSpeed : 300,
    //singleItem : true,
    autoPlay : 5000,
    pagination: false,
    dots: false,
    // "singleItem:true" is a shortcut for:
     items : 4,
    //  navigation: true,
    //   navigationText: ["<img src='assets/images/arrow_prev.png'>","<img src='assets/images/arrow_next.png'>"],
    margin : 20,
    singleItem : false,
    responsive:{
      0:{
        items:1,
     
      },
      600:{
        items:3,
        nav:false
      },
      800:{
        items:3,
  
    
      },
      1000:{
        items:4,
       
  
      }
      }
  }

  constructor() { }

  ngOnInit() {

    
  }

}
